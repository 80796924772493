import { createContext } from 'react'
import {Item} from "../../common/models/Item";
import {BarcodeSelection} from "../../common/models/BarcodeSelection";
import {PriceAdjustmentDelta} from "../../common/models/PriceAdjustment";

interface IPriceChangesContext {
  isProductSelectorOpen: boolean
  isPriceChangesSelectionOpen: boolean
  isPriceAdjustmentOpen: boolean
  allItems: Item[]
  selectedItems: Item[]
  stickerQuantities: any
  prices: any
  priceAdjustment: PriceAdjustmentDelta

  closeProductSelector()
  closePriceChangesSelection()
  closePriceAdjustment()
  openProductSelector()
  openPriceChangesSelection()
  openPriceAdjustment()
  setSelectedItems(items: Item[])
  setStickerQuantities(quantities: any)
  setPrices(prices: any)
  setPriceAdjustment(adjustment: PriceAdjustmentDelta)
  savePriceChangessSelection(selectionName: string): Promise<void>
  deletePriceChangessSelection(
    PriceChangesSelection: BarcodeSelection,
  )
  loadPriceChangessSelection(PriceChangesSelection: BarcodeSelection)
  changePrices()

  isPriceAdjusted(): boolean
  calculatePrice(originalPrice: number): number
  priceAdjustmentTitle(): string
}

export const PriceChangesContext = createContext<IPriceChangesContext>(
  {} as any,
)
