import { createContext } from 'react'
import {UpcCode} from "../../common/models/UpcCode";

interface IUpcCodesContext {
  upcCodes: UpcCode[]
}

export const UpcCodesContext = createContext<IUpcCodesContext>(
  {} as any,
)
